import { Page } from "../layout/Page";
import { NewsPage } from "./NewsPage";
import { AdvertsList } from "../components";
import CarouselBlock from "../components/WeatherCarusel/CarouselBlock";

export const HomePage = () => {
  return (
    <Page>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <AdvertsList />
        </div>
        <div className="col-md-6 col-sm-12">
          <CarouselBlock />
        </div>
      </div>
      <NewsPage />
    </Page>
  );
};
