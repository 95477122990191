import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
//import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./scss/portal.scss";
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import "bootstrap/dist/js/bootstrap.min";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
