export enum NewsSource {
  Сайт = 'Сайт',
  Телеграм = 'Телеграм',
  Инстаграм = 'Инстаграм',
  'Информация УК' = 'Информация УК',
  'Прочее' = 'Прочее',
}
export interface NewsData {
  Id: string;
  DateTime: Date;
  Title: string;
  Tags?: string;
  Source?: NewsSource;
  LinkToSource?: string;
}

const news: NewsData[] = [
  {
    Id: '232232',
    DateTime: new Date(),
    Title: 'Test заголовок',
    Tags: 'УК,новость',
    Source: NewsSource.Инстаграм,
    LinkToSource: 'http://ya.ru',
  },
  {
    Id: '123223122',
    DateTime: new Date('2015-10-01'),
    Title:
      '<ul><li >Test2</li> <li>заголовок</li><li><img src="https://itchief.ru/assets/images/covers/javascript-async-await.png"/></li><ul> ',
    Tags: 'новость',
    Source: NewsSource.Прочее,
  },
  {
    Id: '1232231s22',
    DateTime: new Date('2015-10-01'),
    Title:
      '<ul><li>Test2</li> <li>заголовок</li><li><img src="https://itchief.ru/assets/images/covers/javascript-async-await.png"/></li><ul> ',
    Tags: 'новость',
    Source: NewsSource.Прочее,
  },
];

const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getNewsData = async (filter: string = ''): Promise<NewsData[]> => {
  await wait(500);
  if (filter.length > 0)
    return news.filter((item) => item.Tags?.includes(filter) === true);
  else return news;
};
