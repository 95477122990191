import axios, { AxiosRequestConfig } from "axios";
import AuthService from "../services/AuthService";

export const API_URL = process.env.REACT_APP_API_URL || "./api"; //process.env.REACT_APP_API_URL;
const TOKEN_NAME = "token";
const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getToken();
  if (token) config.headers!.Authorization = `Bearer ${token}`;
  config.baseURL = API_URL;
  console.log(token);
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    //console.log(error.response.status);
    //console.log(error.config._isRetry);
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        const response = await AuthService.checkAuth();
        //   console.log("refresh...");
        setToken(response.token);
        return api.request(originalRequest);
      } catch {
        setToken(null);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
export const setToken = (token: string | null): void => {
  if (token) localStorage.setItem(TOKEN_NAME, token);
  else localStorage.removeItem(TOKEN_NAME);
};
export const getToken = (): string | null => {
  return localStorage.getItem(TOKEN_NAME);
};
