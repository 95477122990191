type Props = {
  title?: React.ReactNode;
  children: React.ReactNode;
};
export const Page = ({ title, children }: Props) => (
  <div className="app-card  mb-4 " style={{ minHeight: "85vh" }}>
    {/* <div className="app-card-header   "> */}
    <div>
      {title && (
        <h3 className="text-center text-white bg-info  pb-1  shadow rounded-2">
          {title}
        </h3>
      )}
    </div>
    {children}
  </div>
);
