import { Link, useLocation } from 'react-router-dom';
import { Logo } from '../components';
import { Page } from '../layout/Page';
export const NotFoundPage = () => {
  const location = useLocation();

  let from = location?.pathname || '/';

  return (
    <Page>
      <div className="app-card p-5 text-center ">
        <div className="app-branding text-center mb-5">
          <Logo />
        </div>

        <h1 className="page-title mb-4 text-danger">
          <p>Адрес {from} не найден,</p>
        </h1>

        <h2 className="mb-4">
          <p> попробуйте перейти в начало сайта ...</p>
        </h2>
        <Link className="btn app-btn-primary" to="/" replace>
          Вернуться на сайт
        </Link>
      </div>
    </Page>
  );
};
