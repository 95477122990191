import { Link } from "react-router-dom";
import { useAppSelector } from "../hooks/redux";
type Props = {
  togglerSidePanel: () => void;
  showSidePanel: boolean;
  mobileScreen: boolean;
};
export const PanelTop = ({
  togglerSidePanel,
  showSidePanel,
  mobileScreen,
}: Props) => {
  const { isUserLogin, userName } = useAppSelector(
    (state) => state.auth.stateAuth
  );
  return (
    <div className="app-header-inner">
      <div className="container-fluid py-2">
        <div className="app-header-content">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto" onClick={togglerSidePanel}>
              <Link
                id="sidepanel-toggler"
                className="sidepanel-toggler d-inline-block d-xl-none"
                to="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  role="img"
                >
                  <title>Меню</title>
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    d="M4 7h22M4 15h22M4 23h22"
                  ></path>
                </svg>
              </Link>
            </div>

            {/* <div className="search-mobile-trigger d-sm-none col"></div>
            <div className="app-search-box col"></div> */}

            <div className="app-utilities col-auto">
              {/*   <div
                className="app-utility-item"
                style={{ backgroundColor: '#0a53be' }}
              >
                <a href="#"></a>
              </div>
              <div className="app-utility-item ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="blue"
                  className="bi bi-check-circle-fill "
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </div>
              <div className="app-utility-item  ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="green"
                  className="bi bi-check-circle-fill app-primary"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
              </div>
              <div className="app-utility-item">
                <a href="#" title="Настройки">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    className="bi bi-gear icon"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z"
                    />
                  </svg>
                </a>
              </div> */}
              {/* <!--//app-utility-item--> */}
              {isUserLogin && (
                <div className="app-utility-item app-user-dropdown dropdown">
                  <Link
                    className="dropdown-toggle"
                    id="user-dropdown-toggle"
                    data-bs-toggle="dropdown"
                    to="#"
                    role="button"
                    aria-expanded="false"
                  >
                    Привет, {userName}
                    <img
                      src="/images/user.png"
                      alt="user profile"
                      className="ms-2"
                    />
                  </Link>
                  <ul
                    className="dropdown-menu "
                    aria-labelledby="user-dropdown-toggle"
                  >
                    <li>
                      <Link className="dropdown-item " to="/cabinet">
                        Личный кабинет
                      </Link>
                    </li>

                    <li>
                      <hr className="dropdown-divider " />
                    </li>
                    <li>
                      <Link className="dropdown-item " to="/logout">
                        Выйти
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
              {/* <!--//app-user-dropdown--> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
