import React from "react";
import UserService from "../services/UserService";
import { useState, useEffect } from "react";
import { UserContact } from "../models/response/UserContact";
import { Page } from "../layout";

const UserCabinet = () => {
  const [contact, setContact] = useState<UserContact>();

  useEffect(() => {
    UserService.getCurrentUser().then((data) => setContact(data.data));
  }, []);

  return (
    <Page title="Личный кабинет">
      <div className="border-1 p-4">
        <p>
          <strong className="fw-bold"> Дата регистрации:</strong>{" "}
          {contact?.date.toLocaleString()}{" "}
          {/* {contact?.date.toLocaleTimeString()} */}
        </p>
        <p>
          <strong className="fw-bold">Id пользвателя:</strong> {contact?.id}
        </p>
        <p>
          <strong className="fw-bold">Имя:</strong> {contact?.firstName}
        </p>
        <p>
          <strong className="fw-bold">Отчество:</strong> {contact?.middleName}
        </p>
        <p>
          <strong className="fw-bold">Фамилия:</strong> {contact?.lastName}
        </p>
        {contact?.communications?.map((c) => {
          return (
            <div key={c.id}>
              <p>
                <strong className="fw-bold">
                  {c.communicationType.description}
                </strong>
              </p>
              <p>
                {" "}
                {c.description} : {c.identify}
              </p>
              <p>Подтверждён? : {c.confirmed ? "Да" : "Нет"}</p>{" "}
              <p>Дата: {c.confirmCodeDate}</p>
            </div>
          );
        })}
      </div>
    </Page>
  );
};

export default UserCabinet;
