import { NavLink, Outlet } from 'react-router-dom';

import { Page } from '../layout/Page';

export const WeatherPage = () => {
  return (
    <Page title="Погода">
      <div className="border-1 p-4">
        <ul className="nav nav-pills infos">
          <li className="nav-item">
            <NavLink
              className="nav-link infos"
              aria-current="page"
              to="/weather/"
            >
              Сейчас
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="hourly">
              Почасовой прогноз
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="dayly">
              Прогноз на 8 дней
            </NavLink>
          </li>
        </ul>

        <Outlet />
      </div>
    </Page>
  );
};
