import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";
import { NewsData } from "./NewsData";

interface Props {
  item: NewsData;
  setFilter: (filter: object) => void;
}

export const NewsItem = ({ item, setFilter }: Props) => {
  const { isUserLogin } = useAppSelector((state) => state.auth.stateAuth);
  return (
    <div className="row justify-content-between align-items-center me-4 ms-4">
      <div className="card-title text-center text-dark bg-light fw-bolder">
        {item.DateTime.toLocaleDateString()}
      </div>
      {isUserLogin && (
        /*
            <span class="text-end copyright ">
                <small class="m-2">@newsItem.CreatedBy</small>
                <small class="m-2">@newsItem.CreatedAt.ToLocalTime()</small>
                <small class="m-2">@newsItem.UpdatedBy</small>
                <small class="m-2">@newsItem.UpdatedAt?.ToLocalTime()</small>
            </span>*/
        <div className=" text-end me-2 ">
          <Link to={`/news/edit/${item.Id}`} className="text-info me-2">
            Редактировать
          </Link>

          <Link to={`/news/delete/${item.Id}`} className="text-danger me-2">
            Удалить
          </Link>
        </div>
      )}
      <div className="fs-5">
        <div dangerouslySetInnerHTML={{ __html: item.Title }} />
      </div>
      <div className="text-muted font-monospace border-top border-light  row">
        <div className="text-start col-10">
          {item.Tags &&
            item.Tags.split(",").map((it) => (
              <button
                key={it}
                className="badge bg-info bg-opacity-50 border-0 m-1"
                onClick={(e) => {
                  e.preventDefault();
                  //setFilter({ filter: undefined });
                  setFilter({ filter: it });
                }}
              >
                {it}
              </button>
            ))}
        </div>
        <div className="col-2">{item.Source?.toString()}</div>
      </div>
    </div>
  );
};
