import React, { useState, useEffect } from "react";
import WeatherCarousel from "./WeatherCarousel";
import { WeatherBlockProps } from "./WeatherCarouselProps";

import "./CarouselBlock.css";
import log from "loglevel";
import Skeleton from "react-loading-skeleton";
import api from "../../utils/api";

const CarouselBlock = () => {
  const [forecasts, setForecasts] = useState<WeatherBlockProps[]>([]);

  const fetchWeatherData = async () => {
    try {
      const res: any = await api.get("/weather");
      const data = res.data;
      //console.log(data);

      //const response = await fetch("https://chertenok.ru/api/weather");
      //const response = await fetch("https://localhost:7137/api/Weather");

      //const data = await response.json();

      // Здесь вы можете преобразовать данные JSON в нужный вам формат
      const transformedForecasts: WeatherBlockProps[] = [
        {
          icon: data.fact.icon,
          temp: data.fact.temp,
          feelsLike: data.fact.feels_like,
          condition: data.fact.condition,
          humidity: data.fact.humidity,
          windSpeed: data.fact.wind_speed,
          windDir: data.fact.wind_dir,
          part_name: "now",
        },
        // добавьте остальные прогнозы в массив
        ...data.forecast.parts.map((part: any) => ({
          icon: part.icon,
          temp: part.temp_avg,
          feelsLike: part.feels_like,
          condition: part.condition,
          humidity: part.humidity,
          windSpeed: part.wind_speed,
          windDir: part.wind_dir,
          part_name: part.part_name,
        })),
      ];

      setForecasts(transformedForecasts);
    } catch (error) {
      log.error("Ошибка при загрузке погодных данных: {error}", error);
    }
  };

  useEffect(() => {
    fetchWeatherData();
  }, []);

  return (
    <div>
      {forecasts.length === 0 ? (
        <div className="card shadow-sm mb-4 me-4 ">
          <div className="card-header logoContainer  ">
            <img
              className="logo"
              src="/images/logo_weather_ru_indoor.svg"
              alt="Logo"
            />
          </div>

          <div className="weatherBlock">
            <div className="imgBlock">
              <Skeleton circle={true} height={50} width={50} baseColor="grey" />
            </div>
            <div className="blockColumn">
              <Skeleton width={100} />
              <Skeleton width={50} />
              <Skeleton width={150} />
              <div className="details">
                <div className="detailItem">
                  <Skeleton width={100} />
                  <Skeleton width={50} />
                </div>
                <div className="detailItem">
                  <Skeleton width={100} />
                  <Skeleton width={50} />
                </div>
                <div className="detailItem">
                  <Skeleton width={100} />
                  <Skeleton width={50} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <WeatherCarousel forecasts={forecasts} />
      )}
    </div>
  );
};

export default CarouselBlock;
