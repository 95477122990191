import { bindActionCreators } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { AppDispatch } from "../store/index";
import { authActions } from "../store/users/auth.slice";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const actions = {
  ...authActions,
};

export const useAppActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
