import { useEffect, useState } from 'react';
import { WeatherBlock, WeatherBlockType } from './WeatherBlock';
import { getOneWeather, OneCallWeather } from './WeatherData';

const f = async () => {
  return await getOneWeather().then();
};
type Props = {
  type: WeatherBlockType;
};
export const Weather = ({ type }: Props) => {
  const [weather, setWeather] = useState<OneCallWeather>();

  useEffect(() => {
    f().then((result) => {
      setWeather(result);
    });
  }, []);

  return (
    <div className="">
      <WeatherBlock weather={weather} title="сейчас" type={type} />
    </div>
  );
};
