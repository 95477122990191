import { Link } from "react-router-dom";

//<div class="card" style="width: 18rem;">
// <div class="card-body">

export const AdvertsList = () => (
  <div
    className="card shadow-sm   mb-4 me-4"
    style={{ width: "42vw-250px", minWidth: "300px" }}
  >
    <h3 className="card-header text-center text-black-50">
      Присоединяйтесь к судам
    </h3>
    <div className="card-body  p-3 p-lg-4">
      <ul className="list-unstyled">
        <li className="list-item">
          <a target="" href="~/document/heat}">
            Иск по расчетам с УК на основе отмены ОСС1 (1-2-3 корп)
          </a>
        </li>
        <li>
          <a target="" href="~/document/oss9}">
            Иск об отмене ОСС9 (2 корп)
          </a>
        </li>
        <li>
          <Link to="~/document/oss8}">Документы по ОСС8</Link>
        </li>
        <li>
          <a target="" href="~/document/oss4}">
            Документы по ОСС4{" "}
          </a>
        </li>
      </ul>
    </div>
  </div>
);
