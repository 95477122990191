import "./App.css";
// import { PanelBottom } from './layout/PanelBottom';
// import { PanelLeft } from './layout/PanelLeft';
import { PanelTop } from "./layout/PanelTop";
// import { RoutesList } from './components/RoutesList';
import { useEffect, useState } from "react";
import { PanelBottom, PanelContent, PanelLeft } from "./layout";
import { RoutesList } from "./components";
import { useAppDispatch } from "./hooks/redux";

// import { PanelContent } from './layout/PanelContent';
import AuthService from "./services/AuthService";
import { authSlice } from "./store/users/auth.slice";
import { withErrorBoundary } from "react-error-boundary";
import log from "loglevel";
import remote from "./utils/remote";

function plain1(logs: any) {
  //switch (logs.level.value)
  var mess = logs.message.params.shift();
  let except;

  if (mess.indexOf("Exceptions") >= 0) except = true;

  return `{"@t":"${logs.timestamp}","@l":"${
    logs.level.label
  }","@mt":${JSON.stringify(logs.message.message)}, "message":${
    mess ? JSON.stringify(mess) : ""
  }${
    except
      ? `,"@x":${
          logs.message.params[3].componentStack
            ? JSON.stringify(logs.message.params[3].componentStack)
            : ""
        }`
      : ""
  } ${
    logs.message.params
      ? ',"params":' +
        JSON.stringify({
          ...logs.message.params,
        })
      : ""
  },"StackTrace":{"trace":${
    logs.stacktrace ? JSON.stringify(logs.stacktrace) + "}" : '""'
  } }`;

  // `[${log.timestamp}] ${log.level.label.toUpperCase()}${
  //   log.logger ? ` (${log.logger})` : ""
  // }: ${log.message}${log.stacktrace ? `\n${log.stacktrace}` : ""}`;
}

remote.apply(log, {
  url: "https://chertenok.ru/logs/api/events/raw?clef",
  headers: { "X-Seq-ApiKey": "qAXBKK44y7K5g4mzFPJS" },
  format: plain1,
});
log.enableAll();

function App() {
  const [leftPanelState, setLeftPanelState] = useState(true);
  const [mobileScreen, setMobileScreen] = useState(false);
  const dispatch = useAppDispatch();

  const responsiveSidePanel = () => {
    let w = window.innerWidth;
    if (w >= 1200) {
      setLeftPanelState(true);
      setMobileScreen(false);
    } else {
      setLeftPanelState(false);
      setMobileScreen(true);
    }
  };

  useEffect(() => {
    try {
      AuthService.checkAuth().then((response) => {
        dispatch(authSlice.actions.addAuthState(response));
      });
    } catch (e) {
      dispatch(authSlice.actions.removeAuthState());
    }
  }, [dispatch]);

  useEffect(() => {
    responsiveSidePanel();
    window.addEventListener("resize", responsiveSidePanel);
    log.info(
      "Page Loaded",
      window.performance.timing.domContentLoadedEventEnd -
        window.performance.timing.navigationStart
    );
    return () => {
      window.removeEventListener("resize", responsiveSidePanel);
    };
  }, []);

  const togglerLeftPanel = () => {
    setLeftPanelState((prev) => !prev);
  };

  return (
    <div className="App">
      <header className="app-header fixed-top">
        <PanelTop
          togglerSidePanel={togglerLeftPanel}
          showSidePanel={leftPanelState}
          mobileScreen={mobileScreen}
        />
        <PanelLeft
          togglerSidePanel={togglerLeftPanel}
          showSidePanel={leftPanelState}
          mobileScreen={mobileScreen}
        />
      </header>
      <div className="app-wrapper">
        <PanelContent>
          <RoutesList />
        </PanelContent>
        <PanelBottom />
      </div>
    </div>
  );
}
export default withErrorBoundary(App, {
  fallback: (
    <p>
      Error, begin from <a href="/">Home</a>
    </p>
  ),
  onError(error, info) {
    //console.log("error=", JSON.stringify({"error":...error}), "info=", info);

    log.error(
      "Exceptions %s: %s",

      error.name,
      error.message,
      {
        name: error.name,
        message: error.message,
        stack: error.stack,
        cause: error.cause,
      },
      info
    );
  },
});
