import { MenuItem } from './MenuItem';
import { MenuItemData } from './MenuItemData';

interface Props {
  menuItems: MenuItemData[];
  activeMenuId: string;
}

export const Menu = ({ menuItems, activeMenuId }: Props) => {
  const menu = menuItems
    .filter((item) => item.ParentId === undefined)
    .map((_menuItem: MenuItemData) => (
      <div key={_menuItem.Id}>
        <MenuItem key={_menuItem.Id} menuItem={_menuItem} />
        {_menuItem.HasSubMenu && (
          <div
            id={`submenu-${_menuItem.Id}`}
            className={`collapse submenu submenu-${_menuItem.Id}`}
            data-bs-parent="#menu-accordion"
          >
            <ul className="submenu-list list-unstyled">
              {menuItems
                .filter((item) => item.ParentId === _menuItem.Id)
                .map((_subItem: MenuItemData) => (
                  <MenuItem key={_subItem.Id} menuItem={_subItem} />
                ))}
            </ul>
          </div>
        )}
      </div>
    ));

  return (
    <nav id="app-nav-main" className="app-nav app-nav-main flex-grow-1">
      <ul className="app-menu list-unstyled accordion" id="menu-accordion">
        {menu}
      </ul>
    </nav>
  );
};
