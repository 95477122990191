import { OneCallWeather } from "./WeatherData";
import "./WeatherBlock.css";
export enum WeatherBlockType {
  current,
  hourly,
  daily,
}

type Props = {
  weather: OneCallWeather | undefined;
  title: string;
  type: WeatherBlockType;
};
export const WeatherBlock = ({ weather, title, type }: Props) => {
  switch (type) {
    case WeatherBlockType.daily:
      return (
        <div className="card-group ">
          {weather?.daily.map((day) => {
            const dt = new Date(day.dt * 1000);
            const titles = (
              <>
                {dt.toLocaleDateString()}
                <br />{" "}
                {dt.toLocaleDateString("ru-ru", {
                  weekday: "long",
                })}{" "}
              </>
            );
            return (
              <div key={day.dt} className="m-4 border-1 shadow ">
                <div className="card-header h3 text-center text-black-50 ">
                  {titles}
                </div>
                <div className="p-1">
                  {weather != null && (
                    <div
                      className="card-body row "
                      style={{ fontSize: ".600rem" }}
                    >
                      <div className="w-50">
                        <img
                          className="news-img "
                          src={day.weather[0].iconSrc}
                          alt={day.weather[0].description}
                          height="128"
                          width="128"
                        />
                      </div>
                      <div className=" w-50">
                        <div>
                          мин {Math.round(day.temp.min)} C макс{" "}
                          {Math.round(day.temp.max)} C
                        </div>
                        {day.weather[0].description}

                        <div>
                          утром {Math.round(day.temp.morn)} (
                          {Math.round(day.feels_like.morn)}) C
                        </div>
                        <div>
                          днём {Math.round(day.temp.day)} (
                          {Math.round(day.feels_like.day)}) C
                        </div>
                        <div>
                          вечером {Math.round(day.temp.eve)} (
                          {Math.round(day.feels_like.eve)}) C
                        </div>
                        <div>
                          ночью {Math.round(day.temp.night)} (
                          {Math.round(day.feels_like.night)}) C
                        </div>
                        <div>Влажность: {day.humidity}%</div>
                        <div>
                          Ветер: {day.wind_speed} м/c{" "}
                          {day.wind_gust && (
                            <div>порывы до {day.wind_gust}</div>
                          )}
                        </div>
                        <div>Восход: {day.sunriseDt.toLocaleTimeString()}</div>
                        <div>Закат: {day.sunsetDt.toLocaleTimeString()}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );

    case WeatherBlockType.hourly:
      return (
        <div className="card-group ">
          {weather?.hourly.map((h) => {
            const dt = new Date(h.dt * 1000);
            const titles = (
              <>
                {dt.toLocaleDateString()}
                <br /> {dt.toLocaleTimeString()}{" "}
              </>
            );
            return (
              <div key={h.dt} className="m-4 border-1 shadow">
                <div className="card-header h3 text-center text-black-50 ">
                  {titles}
                </div>
                <div className="p-2">
                  {weather != null && (
                    <div
                      className="card-body row "
                      style={{ fontSize: ".600rem" }}
                    >
                      <div className="w-50">
                        <img
                          className="news-img "
                          src={h.weather[0].iconSrc}
                          alt={h.weather[0].description}
                          height="128"
                          width="128"
                        />
                      </div>
                      <div className=" w-50">
                        <div>
                          {Math.round(h.temp)} ({Math.round(h.feels_like)}) C{" "}
                        </div>
                        {h.weather[0].description}

                        <div>Влажность: {h.humidity}%</div>
                        <div>
                          Ветер: {h.wind_speed} м/c{" "}
                          {h.wind_gust && <div>порывы до {h.wind_gust}</div>}
                        </div>
                        <div>Вероятность осадков: {h.pop * 100} %</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );

    default:
      return (
        <div
          className="card shadow-sm   mb-4"
          style={{ width: "40vw-250px", minHeight: "22vh", minWidth: "300px" }}
        >
          <div className="card-header h3 text-center text-black-50">
            {title}
          </div>
          <div className="p-2">
            {weather != null && (
              <div className="card-body row " style={{ fontSize: ".600rem" }}>
                <div className="w-50">
                  <img
                    className="news-img "
                    src={weather.current.weather[0].iconSrc}
                    alt={weather.current.weather[0].description}
                    height="64"
                    width="64"
                  />
                </div>
                <div className="w-50">
                  {Math.round(weather?.current.temp)}C,{" "}
                  {weather?.current.weather[0].description}
                  <div>
                    Ошущается как: {Math.round(weather?.current.feels_like)} C
                  </div>
                  <div>Влажность: {weather?.current.humidity}%</div>
                  <div>
                    Ветер: {weather?.current.wind_speed} м/c{" "}
                    {weather?.current.wind_gust && (
                      <>порывы до {weather?.current.wind_gust}</>
                    )}
                  </div>
                  <div>
                    Восход: {weather.current.sunriseDt.toLocaleTimeString()}
                  </div>
                  <div>
                    Закат: {weather.current.sunsetDt.toLocaleTimeString()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
  }
};
