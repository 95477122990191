import axios, { AxiosResponse } from "axios";
import api, { API_URL } from "../utils/api";
import { AuthResponse } from "../models/response/AuthResponse";
import { getToken } from "../utils/api";
import jwtDecode, { JwtPayload } from "jwt-decode";
import log from "loglevel";

interface JWTUser extends JwtPayload {
  Id: string;
  Login: string;
  Role: string[];
}

export default class AuthService {
  static async login(
    login: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>("/users/login", { login, password });
  }

  static async registration(
    login: string,
    password: string
  ): Promise<AxiosResponse> {
    return api.post<AuthResponse>("/users/registration", {
      login,
      password,
    });
  }

  static async logout(): Promise<AxiosResponse<void>> {
    return api.post<void>("/users/logout");
  }

  static async checkAuth(): Promise<AuthResponse> {
    var token = getToken();
    let user: AuthResponse | null = null;
    if (token) {
      try {
        user = AuthService.checkToken(token);
      } catch (e) {
        log.error("AuthService.checkToken error", e);
      }
      if (user != null) return user;
      else
        return axios
          .get<AuthResponse>(`${API_URL}/users/refresh-token`, {
            withCredentials: true,
          })
          .then((res) => res.data);
    } else return Promise.reject();
  }

  static checkToken = (token: string): AuthResponse | null => {
    try {
      var decodedToken = jwtDecode<JWTUser>(token);
      // JWT exp is in seconds
      const exp = (decodedToken?.exp && decodedToken.exp * 1000) ?? 0;
      if (new Date().getTime() > exp) {
        //  console.log("Token expired.");
        return null;
      } else {
        //console.log("Valid token");
        const result: AuthResponse = {
          isUserLogin: true,
          login: decodedToken.Login,
          userName: decodedToken.Login,
          token: token,
          roles: decodedToken.Role,
        };
        return result;
      }
    } catch {
      //  console.log("invalid token");
      return null;
    }
  };
}
