import { AxiosResponse } from "axios";
import { UserContact } from "../models/response/UserContact";
import api from "../utils/api";

export default class UserService {
  static async fetchUsers(): Promise<AxiosResponse<string[]>> {
    return api.get<string[]>("/users");
  }
  static getCurrentUser(): Promise<AxiosResponse<UserContact>> {
    return api.get<UserContact>("/users/contact");
  }
}
