import { Link } from "react-router-dom";
import { Logo, Menu } from "../components";
import { getMenuItems } from "../components/VerticalMenu/MenuItemData";
import { useAppSelector } from "../hooks/redux";
import "./PanelLeft.css";

type Props = {
  togglerSidePanel: () => void;
  showSidePanel: boolean;
  mobileScreen: boolean;
};

export const PanelLeft = ({
  togglerSidePanel,
  showSidePanel,
  mobileScreen,
}: Props) => {
  const { isUserLogin } = useAppSelector((state) => state.auth.stateAuth);
  return (
    <div
      id="app-sidepanel"
      className={`app-sidepanel ${
        showSidePanel ? "sidepanel-visible" : "sidepanel-hidden"
      }`}
    >
      <div
        id="sidepanel-drop"
        className="sidepanel-drop"
        onClick={togglerSidePanel}
      ></div>
      <div
        className="sidepanel-inner d-flex flex-column"
        onClick={() => {
          if (mobileScreen) togglerSidePanel();
        }}
      >
        <Link to="#" id="sidepanel-close" className="sidepanel-close d-xl-none">
          &times;
        </Link>
        <Logo />
        <Menu menuItems={getMenuItems(isUserLogin)} activeMenuId="" />
      </div>
    </div>
  );
};
