import log from "loglevel";

const pathImage = "/images/weather/style01/";
const appid = "";

type WeatherSection = {
  id: number;
  main: string;
  description: string;
  icon: string;
  iconSrc: string;
};
type Wind = { speed: number; gust: number; deg: number };
export type CurrentWeatherSection = {
  dt: number;
  sunrise: number;
  sunset: number;
  sunriseDt: Date;
  sunsetDt: Date;
  temp: number;
  feels_like: number;
  pressure: number;
  humidity: number;
  dew_point: number;
  clouds: number;
  uvi: number;
  visibility: number;
  wind_speed: number;
  wind_gust: number;
  wind_deg: number;
  rain: {
    "1h": number;
  };
  snow: {
    "1h": number;
  };
  weather: WeatherSection[];
};

export type WeatherHourlySection = {
  dt: number;
  temp: number;
  feels_like: number;
  pressure: number;
  humidity: number;
  dew_point: number;
  clouds: number;
  uvi: number;
  visibility: number;
  wind_speed: number;
  wind_gust: number;
  wind_deg: number;
  pop: number;
  rain: {
    "1h": number;
  };
  snow: {
    "1h": number;
  };
  weather: WeatherSection[];
};

export type WeatherDailySection = {
  dt: number;
  sunrise: number;
  sunriseDt: Date;
  sunset: number;
  sunsetDt: Date;
  moonrise: number;
  moonset: number;
  moon_phase: number;
  temp: {
    morn: number;
    day: number;
    eve: number;
    night: number;
    min: number;
    max: number;
  };
  feels_like: {
    morn: number;
    day: number;
    eve: number;
    night: number;
  };
  pressure: number;
  humidity: number;
  dew_point: number;
  wind_speed: number;
  wind_gust: number;
  wind_deg: number;
  clouds: number;
  uvi: number;
  pop: number;
  rain: number;
  snow: number;
  weather: WeatherSection[];
};

export type OneCallWeather = {
  lat: number;
  lon: number;
  timezone: string;
  timezone_offset: number;
  current: CurrentWeatherSection;
  hourly: WeatherHourlySection[];
  daily: WeatherDailySection[];

  alerts: {
    sender_name: string;
    event: string;
    start: number;
    alerts: number;
    description: string;
    tags: string;
  };
};

export type CurrentWeather = {
  coord: {
    lon: number;
    lat: number;
  };
  weather: WeatherSection[];
  base: string;
  main: {
    temp: number;
    feels_like: number;
    temp_min: number;
    temp_max: number;
    pressure: number;
    humidity: number;
    sea_level: number;
    grnd_level: number;
  };
  visibility: number;
  wind: Wind;
  clouds: {
    all: number;
  };
  rain: {
    "1h": number;
    "3h": number;
  };
  snow: {
    "1h": number;
    "3h": number;
  };
  dt: number;
  dtDt: Date;
  sys: {
    type: number;
    id: number;
    county: string;
    sunrise: number;
    sunset: number;
    sunriseDt: Date;
    sunsetDt: Date;
  };
  timezone: number;
  id: number;
  name: string;
  cod: number;
};

const coord = {
  lat: 55.878735,
  lon: 37.539088,
};

export const getCurrentWeather = async (): Promise<CurrentWeather> => {
  let res: CurrentWeather;
  const a = await getCurrentWeatherString();

  log.debug("getCurrentWeatherString", a);
  res = JSON.parse(a.toString());
  res.dtDt = new Date(res.dt * 1000);
  res.sys.sunriseDt = new Date(res.sys.sunrise * 1000);
  res.sys.sunsetDt = new Date(res.sys.sunset * 1000);
  res.weather[0].iconSrc = `${pathImage}${res.weather[0].icon}.png`;
  //console.log(res);
  return res;
};

export const getCurrentWeatherString = async (): Promise<string> => {
  const response = await fetch(
    `https://api.openweathermap.org/data/2.5/weather?lat=${coord.lat}&lon=${coord.lon}&appid=${appid}&lang=ru&units=metric`
  ).catch((e) => {
    log.debug(" getCurrentWeatherString  error", e);
    return Promise.reject(e);
  });
  if (response.ok) {
    const res = await response.text();
    return res;
  } else return Promise.reject();
};

export const getOneWeather = async (): Promise<OneCallWeather> => {
  let res: OneCallWeather;
  const a = await getOneWeatherString();
  res = JSON.parse(a.toString());
  res.current.sunriseDt = new Date(res.current.sunrise * 1000);
  res.current.sunsetDt = new Date(res.current.sunset * 1000);
  for (let index = 0; index < res.daily.length; index++) {
    res.daily[index].sunriseDt = new Date(res.daily[index].sunrise * 1000);
    res.daily[index].sunsetDt = new Date(res.daily[index].sunset * 1000);
    res.daily[
      index
    ].weather[0].iconSrc = `${pathImage}${res.daily[index].weather[0].icon}.png`;
  }

  for (let index = 0; index < res.hourly.length; index++) {
    res.hourly[
      index
    ].weather[0].iconSrc = `${pathImage}${res.hourly[index].weather[0].icon}.png`;
  }

  res.current.weather[0].iconSrc = `${pathImage}${res.current.weather[0].icon}.png`;
  //console.log(res);
  return res;
};

//https://api.openweathermap.org/data/2.5/onecall?lat=55.878735&lon=37.539088&lang=ru&&exclude=minutely
export const getOneWeatherString = async (): Promise<string> => {
  /* const response = await fetch(
    `https://api.openweathermap.org/data/2.5/onecall?lat=${coord.lat}&lon=${coord.lon}&appid=${appid}&lang=ru&&exclude=minutely&units=metric`,
  ).catch((e) => {
   log.error("getOneWeatherString error",e);
    return Promise.reject(e);
  });
  if (response.ok) {
    const res = await response.text();
    return res;
  }
   else
  */ return Promise.reject();
};
