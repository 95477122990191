interface Props {
  children: React.ReactNode;
}
export const PanelContent = ({ children }: Props) => (
  <div className="app-content pt-3 p-md-1 pt-lg-3  ">
    <div className="app-content pt-4 ">
      <div className="container-fluid pt-4 pe-4  ">
        <div className="br-pagebody pd-t-10 pd-b-30 mt-3 ">{children}</div>
      </div>
    </div>
  </div>
);
