import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux";

import { getNewsData, NewsData } from "./NewsData";
import { NewsItem } from "./NewsItem";

export const NewsList = () => {
  interface IFilter {
    filter?: string;
  }
  const [filter, setFilter] = useState<IFilter>({ filter: undefined });

  const { isUserLogin } = useAppSelector((state) => state.auth.stateAuth);
  const [newsData, setNewsData] = React.useState<NewsData[]>([]);

  React.useEffect(() => {
    const getNews = async () => {
      const foundResults = await getNewsData(filter.filter);
      setNewsData(foundResults);
    };
    getNews();
  }, [filter]);

  const newsRender = newsData.map((item) => (
    <NewsItem key={item.Id} item={item} setFilter={setFilter} />
  ));

  return (
    <>
      {/* <!-- card-header --> */}
      {filter.filter !== undefined && (
        <div className="text-center bg-body border-warning border  text-danger m-2 p-2">
          <p>Установлен фильтр новостей по тэгу {filter.filter}</p>
          <button
            className="btn btn-light"
            onClick={(e) => {
              e.preventDefault();
              setFilter({ filter: undefined });
            }}
          >
            Снять фильтр
          </button>
        </div>
      )}
      {isUserLogin && (
        <div className="link-secondary m-2 text-end">
          <Link to="/news/add">Добавить новость</Link>
        </div>
      )}
      <div className="app-card-header rounded-bottom news-img fs-6 mt-4">
        {newsRender}
      </div>
    </>
    //   {/* <!-- card-header --> */}
  );
};
