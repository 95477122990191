import { AxiosError } from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate, Path, useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { Page } from "../layout/Page";
import AuthService from "../services/AuthService";
import { authSlice } from "../store/users/auth.slice";
import { Logo } from "./Logo";

// Fake API Network Call
// const apiLogin = (username: string, password: string) => {
//   return new Promise<void>((resolve, reject) => {
//     if (username === "react" && password === "react") {
//       resolve();
//     } else {
//       reject();
//     }
//   });
// };

type Prop = {
  login: boolean;
};
const LoginForm = (props: Prop) => {
  // type FormData = {
  //   login: string;
  //   password: string;
  // };
  const { stateAuth } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  //const { setLogged, logged } = useAuthUser();
  const login = props.login === null ? true : props.login;
  const [errorMessage, setErrorMessage] = useState<string>();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  //const [logout] = useState<boolean>(!props.login)
  // const { register, handleSubmit } = useForm<FormData>();
  const dispatch = useAppDispatch();
  const location = useLocation();

  //const { from } = location.state || { from: { pathname: '/' } };
  let from = (location?.state as Path)?.pathname || "/";

  useEffect(() => {
    if (!login) {
      if (stateAuth.isUserLogin) {
        AuthService.logout();
        dispatch(authSlice.actions.removeAuthState());
      }
      navigate("/");
    }
  }, [login, navigate, dispatch, stateAuth.isUserLogin]);

  if (!login) {
    if (stateAuth.isUserLogin) {
      //   setTimeout(() => {
      AuthService.logout();
      dispatch(authSlice.actions.removeAuthState());
      // }, 1);
      return null;
    } else return <Navigate to="/" replace />;
    //(
    // <Navigate to="/" replace={false} />
    /*  <Page>
        <div className="auth-main-col text-center p-5">
          <div className="d-flex flex-column align-content-end">
            <div className="app-auth-body mx-auto">
              <Logo />
              <h2 className="auth-heading text-center mb-5">
                Вы действительно хотите выйти ?
              </h2>
              <button
                className="btn btn-warning text-center mb-5"
                onClick={handleLogoutClick}
              >
                Выйти
              </button>
            </div>
          </div>
        </div>
      </Page>
      */
    // );
  }

  // function handleLogoutClick(e: React.MouseEvent<HTMLElement>) {
  //   e.preventDefault();
  //   setLogged(false);
  //   navigate('/');
  // }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const loginNode = e.currentTarget[0] as HTMLFormElement;
    const passwordNode = e.currentTarget[1] as HTMLFormElement;

    AuthService.login(loginNode.value, passwordNode.value)
      .then(({ data }) => {
        dispatch(authSlice.actions.addAuthState(data));
        navigate(from, { replace: true });
        // UserService.fetchUsers().then((data) => {
        //   console.log(data);
        // });
      })
      .catch(() => {
        //dispatch(false);
        setErrorMessage("Имя пользователя или пароль не существуют");
        // UserService.fetchUsers().then((data) => {
        //   console.log(data);
        /// });
      });
  }

  return (
    <Page>
      <div className=" auth-main-col text-center p-5">
        <div className="d-flex flex-column align-content-end">
          <div className="app-auth-body mx-auto">
            <Logo />
            <h2 className="auth-heading text-center mb-5">Авторизация</h2>
            <div className="auth-form-container text-left">
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              <form className="auth-form login-form" onSubmit={handleSubmit}>
                <div className="email mb-3">
                  <label className="sr-only" htmlFor="signin-email">
                    Email
                  </label>
                  <input
                    id="login"
                    name="login"
                    type="text"
                    className="form-control signin-email"
                    placeholder="Email address"
                    required
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />
                </div>
                {/* <!--//form-group--> */}
                <div className="password mb-3">
                  <label className="sr-only" htmlFor="signin-password">
                    Password
                  </label>
                  <input
                    id="signin-password"
                    name="password"
                    type="password"
                    className="form-control signin-password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                  />
                  <div className="extra mt-3 row justify-content-between">
                    {/* <!--//col-6--> */}
                    <div className="">
                      <div className="forgot-password text-right">
                        <a href="reset-password.html">Забыли пароль?</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn app-btn-primary btn-block theme-btn m-2"
                    onClick={(e) => {
                      e.preventDefault();
                      AuthService.login(userName, password)
                        .then(({ data }) => {
                          dispatch(authSlice.actions.addAuthState(data));
                          navigate(from, { replace: true });
                        })
                        .catch((e: AxiosError<any>) => {
                          setErrorMessage(
                            e.response?.status === 401
                              ? e.response?.data.auth.errors[0].errorMessage
                              : e.message
                          );
                        });
                    }}
                  >
                    Войти
                  </button>

                  <button
                    type="submit"
                    className="btn app-btn-primary btn-block theme-btn m-2"
                    onClick={(e) => {
                      debugger;
                      e.preventDefault();
                      AuthService.registration(userName, password)
                        .then(({ data }) => {
                          dispatch(authSlice.actions.addAuthState(data));
                          navigate(from, { replace: true });
                        })
                        .catch((e) => {
                          setErrorMessage(
                            "Имя пользователя или пароль не существуют"
                          );
                        });
                    }}
                  >
                    Регистрация
                  </button>
                </div>
              </form>

              <div className="auth-option text-center pt-5">
                Нет аккаунта? Зарегистрируйся{" "}
                <a className="text-link" href="signup.html">
                  тут
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
    /*
    <div>
      <Card style={{ minHeight: '10em' }}>
        <h1 className="heading-2">Welcome to Firebase, almost...</h1>
        <p>
          This mock application will demonstrate React Router with nested
          layouts and a strategy for authenticated (protected) routes.
        </p>
        <p>
          The username is <strong>react</strong> and the password is{' '}
          <strong>react</strong>
        </p>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <form className="spacing" onSubmit={handleSubmit}>
          <input type="text" placeholder="Username" required />
          <input type="password" placeholder="Password" required />
          <button type="submit" className="button">
            Login
          </button>
        </form>
      </Card>
    </Panel>
    */
  );
};

export default LoginForm;
