import { Link } from 'react-router-dom';
interface Props {
  withText?: boolean;
}

export const Logo = ({ withText = true }: Props) => (
  <div className="app-branding">
    <Link className="app-logo" to="/">
      <img className="logo-icon me-2" src="/images/app-logo.svg" alt="logo" />

      {withText === true && <span className="logo-text m-2">ЖК Город</span>}
    </Link>
  </div>
);
