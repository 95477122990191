import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./WeatherCarousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface WeatherBlockProps {
  icon: string;
  temp: number;
  feelsLike: number;
  condition: string;
  humidity: number;
  windSpeed: number;
  windDir: string;
  part_name: string;
}

const WeatherBlock: React.FC<WeatherBlockProps> = ({
  icon,
  temp,
  feelsLike,
  condition,
  humidity,
  windSpeed,
  windDir,
  part_name,
}: WeatherBlockProps) => {
  return (
    <div className="weatherBlock">
      <div className="imgBlock">
        <img
          className="weatherIcon"
          src={`/images/weather/${icon}.svg`}
          alt={condition}
        />
      </div>
      <div className="blockColumn">
        <div className="temperature">{part_name}</div>

        <div className="temperature">{temp}°C</div>
        <div className="condition">{condition}</div>
        <div className="details">
          <div className="detailItem">
            <div className="detailTitle">Feels like</div>
            <div className="detailValue">{feelsLike}°C</div>
          </div>
          <div className="detailItem">
            <div className="detailTitle">Humidity</div>
            <div className="detailValue">{humidity}%</div>
          </div>
          <div className="detailItem">
            <div className="detailTitle">Wind</div>
            <div className="detailValue">
              {windSpeed} m/s {windDir}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface WeatherCarouselProps {
  forecasts: WeatherBlockProps[];
}

const WeatherCarousel: React.FC<WeatherCarouselProps> = ({ forecasts }) => {
  return (
    <div className="card shadow-sm mb-4 me-4 ">
      <div className="card-header logoContainer  ">
        <img
          className="logo"
          src="/images/logo_weather_ru_indoor.svg"
          alt="Logo"
        />
      </div>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        swipeable={true}
        dynamicHeight={false}
        interval={3000}
        selectedItem={0}
        showIndicators={true}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const item = forecasts[index];
          return (
            <li
              className={isSelected ? "indicatorActive" : "indicator"}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={index}
            >
              {item.part_name}
            </li>
          );
        }}
      >
        {forecasts.map((forecast, index) => (
          <div key={index}>
            <WeatherBlock {...forecast} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default WeatherCarousel;
