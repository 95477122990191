import { Outlet, useOutlet } from 'react-router-dom';
import { NewsList } from '../components';
import { Page } from '../layout/Page';

export const NewsPage = () => {
  const outlet = useOutlet();
  return (
    <Page title="Лента новостей">{outlet ? <Outlet /> : <NewsList />}</Page>
  );
};
