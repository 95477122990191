import { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { Arrow } from './Arrow';
import { MenuItemData } from './MenuItemData';

interface Props {
  menuItem: MenuItemData;
}

export const MenuItem = ({ menuItem }: Props) => {
  if (menuItem.ParentId) {
    return (
      <li className="submenu-item">
        <NavLink
          className={({ isActive }) =>
            `submenu-link  ${isActive ? 'active' : ''}`
          }
          to={menuItem.Link}
        >
          {menuItem.Name}
        </NavLink>
      </li>
    );
  }

  const submenu = menuItem.HasSubMenu ? ' submenu-toggle ' : '';
  const databstoggle = menuItem.HasSubMenu ? 'collapse' : '';

  const onMenuClick = (e: MouseEvent) => {
    if (e.currentTarget.classList.contains('submenu-toggle')) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <li className="nav-item">
      <NavLink
        to={menuItem.Link}
        className={({ isActive }) =>
          `nav-link ${isActive ? 'active' : ''} ${submenu} `
        }
        data-bs-toggle={databstoggle}
        data-bs-target={`#submenu-${menuItem.Id}`}
        aria-expanded="false"
        aria-controls={`submenu-${menuItem.Id}`}
        onClick={onMenuClick}
      >
        {menuItem.Ico && (
          <span
            className="nav-icon"
            dangerouslySetInnerHTML={{ __html: menuItem.Ico }}
          />
        )}
        <span className="nav-link-text">{menuItem.Name}</span>
        {menuItem.HasSubMenu && <Arrow />}
      </NavLink>
      {/* <!--//nav-link--> */}
    </li>
  );
};
