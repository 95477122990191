import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { HomePage } from "../Pages/HomePage";
import { NewsPage } from "../Pages/NewsPage";
import { NotFoundPage } from "../Pages/NotFoundPage";
import { WeatherPage } from "../Pages/WeatherPage";
import Login from "./LoginForm";
import { NewsAdd } from "./News/NewsAdd";
import { NewsList } from "./News/NewsList";
import { Weather } from "./Weather/Weather";
import { WeatherBlockType } from "./Weather/WeatherBlock";
import { useAppSelector } from "../hooks/redux";
import UserCabinet from "./UserCabinet";

export const RoutesList = () => {
  //if (logged === null) return <div>Loading...</div>;
  //if (logged !== true) return <Redirect push to="/auth" />;
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/cabinet" element={<UserCabinet />}></Route>
      <Route path="/weather" element={<WeatherPage />}>
        <Route
          path="dayly"
          element={<Weather type={WeatherBlockType.daily} />}
        />
        <Route
          path="hourly"
          element={<Weather type={WeatherBlockType.hourly} />}
        />
        <Route index element={<Weather type={WeatherBlockType.current} />} />
      </Route>

      <Route path="/news" element={<NewsPage />}>
        <Route index element={<NewsList />} />
        <Route path="add" element={<RequireAuth />}>
          <Route index element={<NewsAdd />} />
        </Route>
        <Route path="*" element={<NewsList />} />
      </Route>

      <Route path="/logout" element={<Login login={false} />} />
      <Route path="/login" element={<Login login={true} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const RequireAuth = () => {
  const { isUserLogin } = useAppSelector((state) => state.auth.stateAuth);
  let location = useLocation();

  if (!isUserLogin) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
