import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthResponse } from "../../models/response/AuthResponse";
import { setToken } from "../../utils/api";

interface AuthState {
  stateAuth: AuthResponse;
}

const initialState: AuthState = {
  stateAuth: {
    isUserLogin: false,
    login: "",
    userName: "",
    token: "",
    roles: [],
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addAuthState(state, action: PayloadAction<AuthResponse>) {
      state.stateAuth = action.payload;
      setToken(state.stateAuth.token);
    },
    removeAuthState(state, action: PayloadAction<void>) {
      state.stateAuth = initialState.stateAuth;
      setToken(null);
    },
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
